.remove-img-btn,
.approve-img-btn,
.download-img-btn,
.reject-reason-btn {
    position: absolute;
    bottom: 0.1rem;
    right: 0.25rem;
    padding: 0.25rem 0.5rem;
    background-color:#ed2626;
    color: white;
    border-radius: 0.375rem;
    display: inline-flex;
    width: max-content;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    i{
        color: #fff;
        font-size: 12px;
    }
}

.approve-img-btn {
    left: 0.25rem;
    background: #10B981;
}

.reject-reason-btn {
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
height: max-content;
}

.download-img-btn {
    top: 0.1rem;
    bottom: auto;
    background: #ffa459;
    padding: 8px;
    i {
    font-size: 14px;
    color: #fff;
    }
}

.uploader-wrapper{
height: auto;
transition: all 0.3s ease;
margin: 10px 0;
visibility: visible;
}

.hide-uploader-wrapper{
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    margin: 0;
    visibility: hidden;
}

.status {
position: absolute;
top: 5px;
left: 5px;
}